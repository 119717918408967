<template>
  <div>
    <div v-if="this.$route.name === 'ReturnCertificate'">
      <ReturnCertificate />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import ReturnCertificate from "@/views/companyApp/returnCertificate/ReturnCertificate.vue";

export default {
  name: 'ReturnCertificateParent',
  components: {
    ReturnCertificate
  }
}
</script>
